/** @jsx jsx */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import Etapa1 from "@/images/ouro/1.svg"
import Etapa2 from "@/images/ouro/2.svg"
import Etapa3 from "@/images/ouro/3.svg"

import { 
    howToWork, 
    faseCard2,
    faseDate,
    fase2,
 } from "../assets/styles/HowToWork.styles";

import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

const HowToWork = () => {
  return (
    <section css={[commonSection, howToWork]} style={{ background: '#ffffff' }}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2 style={{ textAlign: 'center' }} css={secTitle}>Para os Medalhistas de Ouro 2025/2026</h2>       
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <div css={faseCard2}
                data-aos-offset={"0"}
                data-aos={"fade-down"}
                data-aos-duration={1200}
                data-aos-once={true}
              >
              <div css={fase2}>
                <img src={Etapa1} />
                <p>Preparação da viagem à China (Alinhamento com TEC UNESCO/Shanghai Normal University, apoio Embaixadas BRA e CHI, Visto e Passagens</p>
              </div>
              <div css={faseDate}>
                <span>
                  Dez/2025 a Mar/2026
                </span>
              </div>
            </div>
          </Col>   
          <Col lg={4} md={4} sm={12}>
            <div css={faseCard2}
                data-aos-offset={"0"}
                data-aos={"fade-down"}
                data-aos-duration={1200}
                data-aos-once={true}
              >
              <div css={fase2}>
                <img src={Etapa2} />
                <p>Intercâmbio dos Vencedores da OPMBR em Xangai</p>
              </div>
              <div css={faseDate}>
                <span>
                  Abril ou Maio de 2026
                </span>
              </div>
            </div>
          </Col>   
          <Col lg={4} md={4} sm={12}>
            <div css={faseCard2}
                data-aos-offset={"0"}
                data-aos={"fade-down"}
                data-aos-duration={1200}
                data-aos-once={true}
              >
              <div css={fase2}>
                <img src={Etapa3} />
                  <p>Cursos de Capacitação de Professores de Matemática do Ensino Médio pelos Vencedores da OPMBR nos seus Estados e Cidades.</p>
              </div>
              <div css={faseDate}>
                <span>
                  Julho a Agosto de 2026
                </span>
              </div>
            </div>
          </Col>   
        </Row>
      </Container>
    </section>
  );
};

export default HowToWork;

/** @jsx jsx */
import React from "react";
import { WhoData } from "@/data";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { jsx } from "@emotion/react";
import Regulamento2023 from "../assets/documentos/REGULAMENTO-DA-2A-OLIMPIADA-DE-PROFESSORES-DE-MATEMATICA-FINAL.pdf"
import Medalhistas23 from "../assets/documentos/MEDALHISTAS_2023.pdf"

import { who } from "../assets/styles/Who.styles";
import {
  secTitle,
  commonSection,
  commonBtn,
  commonBtn2,
} from "../assets/styles/layout.styles";

const Who = () => {
  return (
    <section id="quem" css={[commonSection, who]}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2 css={secTitle}>Para quem é?</h2>
          </Col>

          <Col lg={12} md={12} sm={12}>
            <ul>
              {WhoData.map(({ icon, title }, index) => (
                <li
                data-aos-offset={"0"}
                data-aos={"fade-down"}
                data-aos-delay={(index+1)*150}
                data-aos-duration={1200}
                data-aos-once={true}
                >
                  <div>
                    <img src={icon} alt="" />
                    <span>{title}</span>
                  </div>
                </li>
              ))}
            </ul>

            <p>
             <strong>OBS:</strong> Haverá uma política de diversidade entre escolas públicas
              seletivas e não seletivas. Os critérios de seleção envolvem
              atributos de resultado dos alunos e de perfil do professor
              (didática, envolvimento dos alunos e da família).
            </p>
            <div style={{ textAlign: `center`, marginTop: 60 }}>
              <a href={Regulamento2023} css={commonBtn} type="submit" style={{ marginRight: 16 }}>
                VER REGULAMENTO 2025
              </a>
               <a href={Medalhistas23} css={commonBtn} type="submit">
                VER MEDALHISTAS 2023
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Who;

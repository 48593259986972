/** @jsx jsx */
import React from "react";
import { SupportImage } from "@/data";
import { jsx } from "@emotion/react";

import {
  supportSection,
  org,
  apoio,
} from "../assets/styles/Support.styles";
import { secTitle, commonSection } from "../assets/styles/layout.styles";

const Footer = () => {
  const {
    vertere,
    ministeiro,
    fractalVertical,
    emap,
    ita,
    itaex,  
    funcamp,  
    imecc,  
    sbm,
    ufc,  
    logo,
    mec,
    embaixada,
    ANPMAT,
    lenovo,
    aegea,
    lenovoF,
    primeiraEscolha,
  } = SupportImage;

  return (
    <section css={[commonSection, supportSection]}>
      <div className="container">
        <div className="row justify-content-center"> 
          <div className="col-lg-12 col-xs-12 col-sm-12 text-center">
            <img src={logo} className="logoFooter" />
          </div>
        </div>
        <div className="row justify-content-center"> 
          <div className="col-lg-12 col-xs-12 col-sm-12 text-center">
            <h2 css={secTitle}>Patrocinadores</h2>
          </div>           
        </div>
        <div className="row justify-content-center" style={{ marginBottom: 40 }}>
          <div className="col-lg-2" />
          <div className="col-lg-4 col-xs-12 col-sm-12">
            <a href="#" target="_blank">
              <img src={aegea} alt="" style={{ width: 240 }}/>
            </a>
          </div>
          <div className="col-lg-4 col-xs-12 col-sm-12">
          <a href="#" target="_blank">
              <img src={lenovoF} alt="" style={{ width: 240 }} />
            </a>
          </div> 
          <div className="col-lg-2" />
        </div>
        <div className="row justify-content-center"> 
          <div className="col-lg-12 col-xs-12 col-sm-12 text-center">
            <h2 css={secTitle}>Apoiadores Técnicos</h2>
          </div>
        </div>
        <div className="row justify-content-center text-center" style={{ marginBottom: 40 }}> 
          <div className="col-lg-4 col-xs-12 col-sm-12">
            <a href="https://institutovertere.org/" target="_blank">
              <img src={vertere} alt="Instituto Vertere" />
            </a>
          </div>
          <div className="col-lg-4 col-xs-12 col-sm-12">
          <a href="https://fractaltecnologia.com.br/" target="_blank">
                <img src={fractalVertical} alt="Fractal" />
              </a>
          </div>
          <div className="col-lg-4 col-xs-12 col-sm-12">
            <a href="https://institutovertere.org/" target="_blank">
              <img src={primeiraEscolha} alt="Instituto Vertere" style={{ width: 220 }} />
            </a>
          </div>
        </div>
        <div className="row justify-content-center"> 
          <div className="col-lg-12 col-xs-12 col-sm-12 text-center">
            <h2 css={secTitle}>Apoio</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={ministeiro} alt="Ministério da Ciência" style={{ width: 170 }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={emap} alt="EMAP" style={{ marginTop: 12 }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={ita} alt="Ita" style={{ width: 220 }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12 text-center">
            <img src={itaex} alt="Itaex" style={{ width: '100%' }} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={funcamp} alt="FUNCAMP" style={{ width: '100%', marginTop: 12 }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={imecc} alt="IMECC" style={{ width: 200 }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={sbm} alt="SBM" style={{ width: '100%' }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={ufc} alt="UFC" style={{ width: '100%', marginTop: 12 }} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={mec} alt="IMECC" style={{ width: '100%' }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12 text-center">
            <img src={embaixada} alt="SBM" style={{ width: '60%' }} />
          </div>
          <div className="col-lg-3 col-xs-12 col-sm-12">
            <img src={ANPMAT} alt="ANPMAT" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
